import React from "react";
import { Checkout } from "./graphql/types";
import {
  useGetWindowHeight,
  useUpdateWindowHeight,
} from "./hooks/WindowHeight";

export const Context = React.createContext<any>(null);

const ContextProvider = ({ children }: any) => {
  const [checkout, setCheckout] = React.useState<Checkout | {}>({});
  const [cartOpen, setCartOpen] = React.useState<boolean>(false);
  const [windowHeight, setWindowHeight] = React.useState(0);

  useGetWindowHeight(setWindowHeight);
  useUpdateWindowHeight(setWindowHeight);

  return (
    <Context.Provider
      value={{
        checkout,
        setCheckout,
        cartOpen,
        setCartOpen,
        windowHeight,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
