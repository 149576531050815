import React, { useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Context } from "../../../ContextProvider";

import { CHECKOUT_LINE_ITEMS_REMOVE } from "../../../graphql";
import { CheckoutLineItem } from "../../../graphql/types";

import * as S from "./RemoveButton.styles";

interface Props {
  lineItem: CheckoutLineItem;
}

const RemoveButton = ({ lineItem }: Props) => {
  const { setCheckout, checkout } = useContext(Context);

  const [removeLineItemInCart, { data }] = useMutation(
    CHECKOUT_LINE_ITEMS_REMOVE,
  );

  const variables = {
    checkoutId: checkout.id,
    lineItemIds: [lineItem.id],
  };

  useEffect(() => {
    if (data) {
      setCheckout(data.checkoutLineItemsRemove.checkout);
    }
  }, [data, setCheckout]);

  return (
    <S.RemoveButton onClick={() => removeLineItemInCart({ variables })}>
      ×
    </S.RemoveButton>
  );
};

export default RemoveButton;
