import * as React from "react";
import { useMutation } from "@apollo/react-hooks";
import { CREATE_CHECKOUT } from "./graphql";
import { Context } from "./ContextProvider";

import Cart from "./components/shared/cart/Cart";
import Header from "./components/shared/header/Header";
import ProductList from "./components/shared/productList/ProductList";
import Footer from "./components/shared/footer/Footer";

import * as S from "./App.styles";
import "./app.css";

const App = () => {
  const { setCheckout } = React.useContext<any>(Context);

  const [createCheckoutInstance, { data, error }] =
    useMutation(CREATE_CHECKOUT);

  React.useEffect(() => {
    createCheckoutInstance({ variables: { input: {} } });
  }, [createCheckoutInstance]);

  React.useEffect(() => {
    if (data) {
      setCheckout(data.checkoutCreate.checkout);
    }
  }, [data, setCheckout]);

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <S.App>
      <Header />
      <S.Inner>
        <ProductList />
        <Footer />
        <Cart />
      </S.Inner>
    </S.App>
  );
};

export default App;
