import * as React from "react";
import UpdateButton from "../updateButton/UpdateButton";
import RemoveButton from "../removeButton/RemoveButton";
import { CheckoutLineItem } from "../../../graphql/types";

import * as S from "./LineItem.styles";
interface Props {
  lineItem: CheckoutLineItem;
}

const LineAtom = ({ lineItem }: Props) =>
  lineItem && lineItem.variant ? (
    <S.LineItem>
      <S.Image>
        {lineItem.variant.image ? (
          <img
            src={lineItem.variant.image.src}
            alt={`${lineItem.title} product shot`}
          />
        ) : null}
      </S.Image>
      <S.Content>
        <S.ContentRow>
          <S.VariantTitle>{lineItem.variant.title}</S.VariantTitle>
          <S.ItemTitle>{lineItem.title}</S.ItemTitle>
        </S.ContentRow>
        <S.ContentRow>
          <S.QuantityContainer>
            <UpdateButton lineItem={lineItem} type="minus" />
            <S.Quantity>{lineItem.quantity}</S.Quantity>
            <UpdateButton lineItem={lineItem} type="plus" />
          </S.QuantityContainer>
          <S.ItemPrice>
            £ {(lineItem.quantity * lineItem.variant.price.amount).toFixed(2)}
          </S.ItemPrice>
          <RemoveButton lineItem={lineItem} />
        </S.ContentRow>
      </S.Content>
    </S.LineItem>
  ) : null;

export default LineAtom;
