import styled from "styled-components";

export const LineItem = styled.li`
  margin-bottom: 20px;
  overflow: hidden;
  backface-visibility: visible;
  min-height: 65px;
  position: relative;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
`;

export const Image = styled.div`
  width: 65px;
  height: 80px;
  border-radius: 3px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: ${props => props.theme.colors.background};
  position: absolute;
`;

export const Content = styled.div`
  width: 100%;
  padding-left: 75px;
`;

export const ContentRow = styled.div`
  display: inline-block;
  width: 100%;
  margin-bottom: 5px;
  position: relative;
`;

export const VariantTitle = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.colors.blue};
  padding-bottom: 4px;
`;

export const ItemTitle = styled.span`
  color: ${props => props.theme.colors.blue};
  font-size: 18px;
  font-weight: 400;
`;

export const ItemPrice = styled.div`
  line-height: 23px;
  float: right;
  font-weight: bold;
  font-size: 18px;
  margin-right: 40px;
`;

export const QuantityContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.blue};
  float: left;
  border-radius: 3px;
`;

export const Quantity = styled.span`
  color: ${props => props.theme.colors.blue};
  width: 38px;
  height: 21px;
  line-height: 23px;
  font-size: 15px;
  border: none;
  text-align: center;
  -moz-appearance: textfield;
  background: transparent;
  border-left: 1px solid ${props => props.theme.colors.blue};
  border-right: 1px solid ${props => props.theme.colors.blue};
  display: block;
  float: left;
  padding: 0;
  border-radius: 0;
`;
