import styled from "styled-components";

export const UpdateButton = styled.button`
  color: ${props => props.theme.colors.blue};
  display: block;
  float: left;
  height: 21px;
  line-height: 16px;
  font-family: monospace;
  width: 25px;
  padding: 0;
  border: none;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  font-size: 18px;
  text-align: center;
`;
