import React from "react";
import { useQuery } from "@apollo/react-hooks";
import Product from "../product/Product";
import Loading from "../loading/Loading";

import { GET_SHOP } from "../../../graphql";

import * as S from "./ProductList.styles";

const ProductList = () => {
  const { data, error, loading } = useQuery(GET_SHOP);

  const products = data && data.collection.products.edges;

  if (loading && !products) {
    return <Loading />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return products ? (
    <S.ProductList>
      {products.map((product: any, i: number) => (
        <Product key={i} product={product.node} />
      ))}
    </S.ProductList>
  ) : null;
};

export default ProductList;
