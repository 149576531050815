import styled from "styled-components";

export const ProductList = styled.div`
  ${props => props.theme.gridStyles.getRowStyles()}
`;

export const Footer = styled.div`
  min-height: 100px;
  position: relative;
`;

export const FooterText = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;
