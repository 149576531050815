import * as React from "react";
import { Context } from "../../../ContextProvider";
import ShoppingBasket from "@mui/icons-material/ShoppingBasket";

import * as S from "./Header.styles";

const Header = () => {
  const { setCartOpen, cartOpen } = React.useContext(Context);

  return (
    <S.Header>
      <S.HeaderInner>
        <S.Title>
          Tess Redburn <br></br>Print Shop
        </S.Title>
        {!cartOpen && (
          <S.CartWrapper>
            <S.ViewCart onClick={() => setCartOpen(true)}>
              <ShoppingBasket fontSize="large" />
            </S.ViewCart>
          </S.CartWrapper>
        )}
      </S.HeaderInner>
    </S.Header>
  );
};

export default Header;
