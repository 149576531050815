import * as React from "react";

export const useGetWindowHeight = (callback: any) => {
    React.useEffect(() => {
        const vh = window.innerHeight;
        callback(vh);
    }, [callback]);
};

export const useUpdateWindowHeight = (callback: any) => {
    React.useEffect(() => {
        window.addEventListener("resize", () => {
            const vh = window.innerHeight;
            callback(vh);
        });
    }, [callback]);
};
