import React from "react";

import * as S from "./Loading.styles";

const Loading = () => (
  <S.SpinnerWrapper>
    <S.Inner>
      <S.Spinner />
    </S.Inner>
  </S.SpinnerWrapper>
);

export default Loading;
