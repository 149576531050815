import * as React from "react";

const ScrollToTop = ({ children }: any) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>{children}</>;
};

export default ScrollToTop;
