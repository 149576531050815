import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div`
  ${props => props.theme.gridStyles.getRowStyles()}
  display: flex;
  justify-content: center;
  flex-direction: row;
  height: 100vh;
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid ${props => props.theme.colors.blue};
  border-right: 2px solid ${props => props.theme.colors.blue};
  border-bottom: 2px solid ${props => props.theme.colors.blue};
  border-left: 4px solid ${props => props.theme.colors.blue};
  background: transparent;
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;
