import React from "react";
import BuyButton from "../buyButton/BuyButton";
import { Context } from "../../../ContextProvider";
import { Product } from "../../../graphql/types";

import * as S from "./Product.styles";

interface Props {
  product: Product;
}

const ProductComponent = ({ product }: Props) => {
  const { checkout } = React.useContext(Context);

  let variantImage = product.images.edges[0].node.src;
  let variant = product.variants.edges[0].node;
  let variantQuantity = 1;

  return (
    <S.Product>
      {product.images.edges.length ? (
        <img src={variantImage} alt={`${product.title} product shot`} />
      ) : null}
      <S.TextWrapper>
        <S.Title>{product.title}</S.Title>
        <S.Description>{product.description}</S.Description>
        <S.Price>£{variant.price.amount}0</S.Price>
        <BuyButton
          variantId={variant.id}
          checkoutId={checkout.id}
          quantity={variantQuantity}
        />
      </S.TextWrapper>
    </S.Product>
  );
};

export default ProductComponent;
