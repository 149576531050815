import * as React from "react";
import * as S from "./Footer.styles";
import InstaIcon from "../../../assets/icons/instagram.png";
import Twitter from "../../../assets/icons/twitter.png";

const Footer = () => (
  <div>
    <S.CopyWrapper>
      <S.CopyText>
        Printing is handled by{" "}
        <a
          href="https://www.theprintspace.co.uk/"
          target="_blank"
          rel="noopener noreferrer"
        >
          The Printspace
        </a>
        . Postage is £10 to anywhere in the UK and Germany. Please allow at
        least a week turnaround.
      </S.CopyText>
      <S.CopyText>
        <a
          href="https://www.theprintspace.co.uk/professional-giclee-art-printing/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Find out more about Giclée printing, and Hahnemühle German Etching
          paper.
        </a>
      </S.CopyText>
      <S.CopyText>
        Many of the prints are also available as original paintings, prices
        starting at £200. Please contact me for more details:
        tess@tessredburn.co.uk
      </S.CopyText>
      <S.CopyText>
        I also sell original paintings at{" "}
        <a
          href="https://www.artfinder.com/tessredburn#/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Artfinder.
        </a>
      </S.CopyText>
    </S.CopyWrapper>
    <S.IconWrapper>
      <a
        href="https://www.instagram.com/tessredburn"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.InstaIcon src={InstaIcon} />
      </a>
      <a
        href="https://www.twitter.com/tessredburn"
        target="_blank"
        rel="noopener noreferrer"
      >
        <S.TwitterIcon src={Twitter} />
      </a>
    </S.IconWrapper>
    <S.Footer>
      <S.Text>©2019 Tess Redburn.</S.Text>
    </S.Footer>
  </div>
);

export default Footer;
