import styled from "styled-components";
import { device } from "../../../styles/media";

export const Header = styled.header`
  background-color: ${props => props.theme.colors.green};
  min-height: 30vh;
  position: relative;
  color: ${props => props.theme.colors.blue};
  display: flex;
  justify-content: space-between;
  @media ${device.md} {
    min-height: 30vh;
  }
`;

export const CartWrapper = styled.div`
  color: ${props => props.theme.colors.blue};
  float: right;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

export const HeaderInner = styled.div`
  ${props => props.theme.gridStyles.getContainerStyles()};
`;

export const Row = styled.div`
  ${props => props.theme.gridStyles.getRowStyles()};
`;

export const Title = styled.h1`
  font-family: ${props => props.theme.fonts.title};
  font-weight: bold;
  padding: 20px 0;
  text-transform: uppercase;
  position: absolute;
  bottom: 0;
  margin: 0;
  font-size: 34px;

  @media ${device.md} {
    font-size: 52px;
  }
`;

export const ViewCart = styled.button`
  font-size: 15px;
  color: ${props => props.theme.colors.blue};
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: ${props => props.theme.colors.background};
  }
`;

export const Items = styled.div`
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 10px 5px 5px;
`;
