import React, { useEffect, useContext } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Context } from "../../../ContextProvider";

import { CHECKOUT_LINE_ITEMS_ADD } from "../../../graphql";

import * as S from "./BuyButton.styles";

interface Props {
  checkoutId: string;
  variantId: string;
  quantity: number;
}

const BuyButton = ({ checkoutId, variantId, quantity }: Props) => {
  const { setCheckout, setCartOpen } = useContext(Context);

  const [addToCart, { data }] = useMutation(CHECKOUT_LINE_ITEMS_ADD);

  const variables = {
    checkoutId,
    lineItems: [{ variantId, quantity }],
  };

  useEffect(() => {
    if (data) {
      setCheckout(data.checkoutLineItemsAdd.checkout);
      setCartOpen(true);
    }
  }, [data, setCheckout, setCartOpen]);

  return (
    <S.BuyButton
      onClick={() => {
        addToCart({ variables });
      }}
    >
      Add to Cart
    </S.BuyButton>
  );
};

export default BuyButton;
