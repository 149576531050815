import React, { useContext, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import { CHECKOUT_LINE_ITEMS_UPDATE } from "../../../graphql";
import { Context } from "../../../ContextProvider";
import { CheckoutLineItem } from "../../../graphql/types";

import * as S from "./UpdateButton.styles";

interface Props {
  lineItem: CheckoutLineItem;
  type: "plus" | "minus";
}

const UpdateButton = ({ lineItem, type }: Props) => {
  const { setCheckout, checkout } = useContext(Context);

  const [updateQuantity, { data }] = useMutation(CHECKOUT_LINE_ITEMS_UPDATE);

  const quantity =
    type === "plus" ? lineItem.quantity + 1 : lineItem.quantity - 1;

  const variables = {
    checkoutId: checkout.id,
    lineItems: [{ id: lineItem.id, quantity }],
  };

  useEffect(() => {
    if (data) {
      setCheckout(data.checkoutLineItemsUpdate.checkout);
    }
  }, [data, setCheckout]);

  return (
    <S.UpdateButton onClick={() => updateQuantity({ variables })}>
      {type === "plus" ? "+" : "-"}
    </S.UpdateButton>
  );
};

export default UpdateButton;
