import styled from "styled-components";

export const Product = styled.div`
  ${props => props.theme.gridStyles.getColStyles(12, 6, 4)}
  color: ${props => props.theme.colors.blue};
  font-family: ${props => props.theme.fonts.body};
  padding: 20px 0;
`;

export const TextWrapper = styled.div`
  padding: 0 5px;
`;

export const Title = styled.h3`
  font-size: 28px;
  margin-top: 1rem;
  margin-bottom: 0.4rem;
`;

export const Price = styled.span`
  display: block;
  font-size: 18px;
  opacity: 0.7;
  margin-bottom: 0.8rem;
`;

export const Option = styled.label`
  display: block;
  width: 100%;
  margin-bottom: 10px;
`;

export const Description = styled.p`
  opacity: 0.8;
  font-size: 18px;
  margin-bottom: 0.4rem;
  margin-top: 0.4rem;
`;
