import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import camporaWoff from "../assets/fonts/campora.woff";
import camporaWoff2 from "../assets/fonts/campora.woff2";

const GlobalStyles = createGlobalStyle`
  ${normalize}

  @font-face {  
    font-family: 'Campora';
    font-weight: 400;  
    font-style: normal;  
    font-display: block; 
    src: local('Custom Font'),       
    url(${camporaWoff2}) format('woff2'),        
    url(${camporaWoff}) format('woff');}

  @import url('https://fonts.googleapis.com/css?family=Work+Sans:400,700&display=swap');
  
  body {  
    -webkit-font-smoothing: antialiased;
  }
  `;

export default GlobalStyles;
