import styled from "styled-components";

export const BuyButton = styled.button`
  float: left;
  background-color: ${props => props.theme.colors.blue};
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
  opacity: 0.9;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &:disabled {
    background: #bfbfbf;
    cursor: not-allowed;
  }
`;
