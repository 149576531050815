import styled from "styled-components";

export const RemoveButton = styled.button`
  color: ${props => props.theme.colors.blue};
  position: absolute;
  right: 0;
  top: 0;
  border: 0;
  background: 0;
  font-size: 20px;
  top: -4px;
  opacity: 0.5;
`;
