import React from "react";
import { createRoot } from "react-dom/client";
import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  concat,
} from "@apollo/client";
import ContextProvider from "./ContextProvider";
import App from "./App";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import GlobalStyles from "./styles/GlobalStyles";
import ScrollToTop from "./components/shared/scrollToTop/ScrollToTop";
import TagManager from "react-gtm-module";
import { StyledEngineProvider } from "@mui/styled-engine";

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID as string,
};

TagManager.initialize(tagManagerArgs);

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_STORE_URI,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      "X-Shopify-Storefront-Access-Token": process.env.REACT_APP_STORE_TOKEN,
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache().restore((window as any).__APOLLO_STATE__),
});

const container = document.getElementById("root");

const root = createRoot(container!);

root.render(
  <ContextProvider>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <ScrollToTop>
            <GlobalStyles />
            <App />
          </ScrollToTop>
        </ApolloProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ContextProvider>,
);
