import styled from "styled-components";

export const Cart = styled.div<{ cartOpen: boolean }>`
  background-color: ${(props) => props.theme.colors.background};
  color: ${(props) => props.theme.colors.blue};
  font-family: ${(props) => props.theme.fonts.body};
  transform: translateX(0);
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 350px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e5e5e5;
  ${(props) => (!props.cartOpen ? "transform: translateX(100%)" : "")};
  transition: transform 0.15s ease-in-out;
`;

export const Title = styled.h1``;

export const Header = styled.header`
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  flex: 0 0 auto;
  display: inline-block;
`;

export const CartClose = styled.button`
  position: absolute;
  right: 9px;
  top: 8px;
  font-size: 35px;
  color: ${(props) => props.theme.colors.blue};
  border: none;
  background: transparent;
  transition: transform 100ms ease;
  cursor: pointer;
  opacity: 0.8;

  &:hover,
  &:focus {
    opacity: 1;
  }
`;

export const CartWrapper = styled.div<{ windowHeight: number }>`
  max-height: calc(${(props) => props.windowHeight} - 297px);
  overflow-y: scroll;
`;

export const CartItems = styled.ul`
  flex: 1 0 auto;
  margin: 0;
  padding: 20px;
`;

export const Footer = styled.footer`
  padding: 20px;
  border-top: 1px solid #e5e5e5;
  flex: 0 0 auto;
`;

export const CartInfo = styled.div`
  padding: 15px 20px 10px;
`;

export const SubTotal = styled.div`
  float: left;
  text-transform: uppercase;
  font-size: 16px;
`;

export const InfoPricing = styled.div`
  float: right;
`;

export const Pricing = styled.div`
  margin-left: 5px;
  font-size: 16px;
`;

export const CheckoutButton = styled.button`
  margin-top: 20px;
  display: block;
  width: 100%;

  float: left;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.blue};
  color: white;
  border: none;
  font-size: 1.2rem;
  padding: 10px 17px;
  cursor: pointer;
  opacity: 0.9;

  &:hover,
  &:focus {
    opacity: 1;
  }

  &:disabled {
    background: #bfbfbf;
    cursor: not-allowed;
  }
`;
