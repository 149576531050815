import styled from "styled-components";
import { device } from "../../../styles/media";

export const Footer = styled.div`
  min-height: 40px;
  position: relative;
  display: flex;
  justify-content: center;
  font-size: 18px;

  @media ${device.md} {
    min-height: 60px;
  }
`;

export const CopyWrapper = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.blue};
  padding: 20px 0 0;

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.blue};
    font-weight: bold;

    &:hover {
      color: ${props => props.theme.colors.green};
    }
  }
`;

export const CopyText = styled.p`
  font-family: ${props => props.theme.fonts.body};
  font-size: 22px;
`;

export const InstaIcon = styled.img`
  height: 80px;
  width: 80px;
  padding: 5px;
`;

export const TwitterIcon = styled.img`
  height: 100px;
  width: 100px;
  padding: 5px;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.p`
  font-size: 12px;
  position: absolute;
  bottom: 0;
  color: ${props => props.theme.colors.blue};
`;
