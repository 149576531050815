import React, { useContext } from "react";
import LineItem from "../lineItem/LineItem";
import { Context } from "../../../ContextProvider";

import * as S from "./Cart.styles";

const Cart = () => {
  const { checkout, cartOpen, setCartOpen } = React.useContext<any>(Context);

  let lineItems =
    checkout &&
    checkout.lineItems &&
    checkout.lineItems.edges.map((lineItem: any) => {
      return (
        <LineItem key={lineItem.node.id.toString()} lineItem={lineItem.node} />
      );
    });

  const openCheckout = () => {
    window.open(checkout.webUrl);
  };

  const { windowHeight } = useContext(Context);

  return checkout ? (
    <S.Cart cartOpen={cartOpen}>
      <S.Header>
        <S.Title>Cart</S.Title>
        <S.CartClose onClick={() => setCartOpen(false)}>×</S.CartClose>
      </S.Header>
      <S.CartWrapper windowHeight={windowHeight}>
        <S.CartItems>{lineItems}</S.CartItems>
      </S.CartWrapper>
      <S.Footer>
        <S.CartInfo>
          <S.SubTotal>Subtotal</S.SubTotal>
          <S.InfoPricing>
            <S.Pricing>£ {checkout?.subtotalPrice?.amount}0</S.Pricing>
          </S.InfoPricing>
        </S.CartInfo>
        <S.CartInfo>
          <S.SubTotal>Taxes</S.SubTotal>
          <S.InfoPricing>
            <S.Pricing>£ {checkout.totalTax?.amount}0</S.Pricing>
          </S.InfoPricing>
        </S.CartInfo>
        <S.CartInfo>
          <S.SubTotal>Total</S.SubTotal>
          <S.InfoPricing>
            <S.Pricing>£ {checkout.totalPrice?.amount}0</S.Pricing>
          </S.InfoPricing>
        </S.CartInfo>
        <S.CheckoutButton onClick={() => openCheckout()}>
          Checkout
        </S.CheckoutButton>
      </S.Footer>
    </S.Cart>
  ) : null;
};

export default Cart;
